<template>
  <div class="grid">
    <div class="grid__image__wrapper" v-for="image in images" :key="image.id">
      <img class="grid__image" :id="image.id" :alt="image.alt" v-lazy="image">
    </div>    
  </div>
</template>

<script>
export default {
  name: "ImageGrid",
  props: {
    images: Array
  }
};
</script>

<style scoped>
.grid {
  --column-width: 33%;
  width: 85%;
  margin: 0 auto 3rem auto;
  display: grid;
  grid-template-columns: repeat(3, var(--column-width));
  grid-auto-rows: calc(5 / var(--column-width));
  justify-items: center;
  align-items: center;
}

.grid__image__wrapper {
  padding: 0.5rem;
}

.grid__image {
  display: block;
  width: 100%;
  box-shadow: 3px 3px 3px black;
}

@media screen and (max-width: 1024px) {
  .grid {
    grid-template-columns: 100%;
  }
}
</style>
