<template>
  <BrandShowcase :brand="brand"/>  
</template>

<script>
import BrandShowcase from "@/components/photography/below/showcase/brands/BrandShowcase.vue";
import brand from "@/lib/photography/brands/hyatt";

export default {
  data() {
    return { brand };
  },
  components: { BrandShowcase },
  metaInfo: {
    title: "Hotel Photography | Hyatt Photography",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Hotel photography on your brand website converts lookers to bookers. We have years of experience working with every major brand and have the capability to finalize image uploads for you."
      }
    ],
    link: [
      {
        vmid: "canonical",
        rel: "canonical",
        href: "https://allthingshospitality.com/photography/hyatt"
      }
    ]
  }
};
</script>
