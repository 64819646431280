import factory from "@/lib/photography/image-factory";
import al from "./aloft";
import cy from "./courtyard";
import ffi from "./fairfield";
import fp from "./four-points";
import ri from "./residence-inn";
import sh from "./sheraton";
import shs from "./shs";
import tps from "./tps";

const brand = "marriott";

export default {
  aloft: factory(brand, al.name, al.tags),
  courtyard: factory(brand, cy.name, cy.tags),
  fairfield: factory(brand, ffi.name, ffi.tags),
  four_points: factory(brand, fp.name, fp.tags),
  residence_inn: factory(brand, ri.name, ri.tags),
  sheraton: factory(brand, sh.name, sh.tags),
  springhill_suites: factory(brand, shs.name, shs.tags),
  towneplace_suites: factory(brand, tps.name, tps.tags)
};
