const def = " - Sheraton";

export default {
  name: "sheraton",
  tags: [
    "Guest Room Starbuks" + def,
    "Guest Room" + def,
    "Guest Room" + def,
    "Guest Room" + def,
    "Guest Room" + def,
    "Guest Room" + def,
    "Guest Room" + def
  ]
};
