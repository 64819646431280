const def = " - Embassy Suites by Hilton";
const nola = " - Embassy Suites by Hilton New Orleans Convention Center";

export default {
  name: "embassy",
  tags: [
    "Exterior" + def,
    "Atrium" + nola,
    "Guest Room" + def,
    "Guest Room" + def,
    "Guest Room" + def,
    "Guest Bath" + def,
    "Guest Room" + nola,
    "Banquet Seating" + nola
  ]
};
