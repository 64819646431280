const def = " - SpringHill Suites";

export default {
  name: "shs",
  tags: [
    "Lobby Seating" + def,
    "Bar Seating" + def,
    "Lobby Seating" + def,
    "Lobby Seating" + def,
    "Front Desk" + def,
    "Lobby Seating" + def,
    "Lobby Seating" + def,
    "Exterior" + def,
    "Lobby Seating" + def,
    "Meeting Space" + def
  ]
};
