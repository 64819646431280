const def = " - Hampton Inn";

export default {
  name: "hampton",
  tags: [
    "Lobby" + def,
    "Pool" + def,
    "Guest Room" + def,
    "Exterior" + def,
    "Seating Area" + def,
    "Guest Room" + def,
    "Front Desk" + def
  ]
};
