<template>
  <div class="button__wrapper">
    <button class="normal minor__brand__button" v-for="(brand, index) in brands" :key="index" @click="selected(brand)" :class="{active: active === brand}">
      {{ modifiedNames[index] }}
    </button>
  </div>
  
</template>

<script>
export default {
  props: {
    brands: {
      type: Array,
      required: true
    }
  },
  methods: {
    selected(brand) {
      this.active = brand;
      this.$emit("selected", brand);
    }
  },
  data() {
    return {
      active: null,
      modifiedNames: this.brands.map(brand => brand.replace(/_/g, " "))
    };
  }
};
</script>

<style scoped>
.button__wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 70%;
  flex-wrap: wrap;
  min-height: 2rem;
  margin: 2rem auto 3rem auto;
}
.minor__brand__button {
  padding: 0 0.5rem;
  background: none;
  border: none;
  border-left: 2px solid #fffefe;
  border-right: 2px solid #fffefe;
  color: #fffefe;
  text-shadow: 0px 3px 6px #000000;
  font-size: 1.25vw;
  text-transform: uppercase;
  margin: 1rem;
  flex-basis: 20%;
  height: 4rem;
}

.minor__brand__button.active {
  color: var(--ath-orange);
}

@media screen and (max-width: 1366px) {
  .minor__brand__button {
    flex-basis: 25%;
    height: 3rem;
    border: none;
    border-bottom: 2px solid white;
  }
}

@media screen and (max-width: 1024px) {
  .minor__brand__button {
    font-size: 2vw;
  }
}

@media screen and (max-width: 768px) {
  .button__wrapper {
    width: 95%;
    margin: 2rem auto;
  }
  .minor__brand__button {
    font-size: 3vw;
    height: 4rem;
  }
}

@media screen and (max-width: 375px) {
  .minor__brand__button {
    height: 2.5rem;
  }
}
</style>
