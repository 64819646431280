const def = " - Homewood Suites by Hilton";

export default {
  name: "homewood-suites",
  tags: [
    "Guest Room" + def,
    "Exterior" + def,
    "Exterior" + def,
    "Guest Room" + def,
    "Guest Room" + def,
    "Guest Room" + def,
    "Porte Cochere" + def,
    "Fitness Center" + def,
    "Guest Room" + def,
    "Guest Room" + def,
    "Patio" + def,
    "Exterior" + def,
    "Patio" + def,
    "Fireplace" + def,
    "Lobby" + def,
    "Entrance" + def,
    "Meeting Space" + def,
    "Indoor Pool" + def,
    "Grill Area" + def,
    "Exterior" + def,
    "Guest Room" + def,
    "Dining" + def
  ]
};
