const def = " - Four Points by Sheraton";

export default {
  name: "four-points",
  tags: [
    "Pool" + def,
    "Lobby Seating" + def,
    "Lobby Seating" + def,
    "Guest Room" + def,
    "Guest Room" + def,
    "Exterior" + def
  ]
};
