const def = " - Hilton";
const promenade = " - Hilton Promenade at Branson Landing";
const convention = " - Hilton Branson Convention Center";
export default {
  name: "hilton",
  tags: [
    "Guest Room" + def,
    "Guest Room" + promenade,
    "Lobby" + convention,
    "Guest Room" + promenade,
    "Room View" + promenade,
    "Guest Room" + promenade,
    "Guest Room" + promenade,
    "View" + promenade
  ]
};
