const def = " - Hilton Garden Inn";
const bakersfield = " - Hilton Garden Inn Bakersfield";
const hollywood = " - Hilton Garden Inn Los Angeles/Hollywood";

export default {
  name: "hgi",
  tags: [
    "Exterior" + bakersfield,
    "Lobby" + bakersfield,
    "Meeting" + bakersfield,
    "Seating Area" + bakersfield,
    "Dining Area" + bakersfield,
    "Banquet Seating" + bakersfield,
    "Exterior" + def,
    "Covered Patio Seating" + hollywood,
    "Guest Room" + def,
    "Patio Seating" + def,
    "Fireplace" + def,
    "Lobby" + hollywood
  ]
};
