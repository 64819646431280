const def = " - Courtyard";

export default {
  name: "courtyard",
  tags: [
    "Outdoor Event" + def,
    "Bar" + def,
    "Outdoor Patio" + def,
    "Meeting Space" + def,
    "Outdoor Firepit" + def,
    "Outdoor Breakfast" + def,
    "Indoor Seating" + def,
    "Guest Room" + def,
    "Outdoor Buffet" + def,
    "Bar" + def,
    "Guest Room" + def,
    "Indoor Seating" + def,
    "Guest Room" + def,
    "Lobby Seating" + def
  ]
};
