const def = " - TownePlace Suites";

export default {
  name: "tps",
  tags: [
    "Guest Room" + def,
    "Bike Rentals" + def,
    "Guest Room" + def,
    "Outdoor Seating" + def,
    "Exterior" + def,
    "Exterior" + def,
    "Guest Room" + def,
    "Guest Room" + def
  ]
};
