const def = " - Holiday Inn Express";

export default {
  name: "hie",
  tags: [
    "Exterior" + def,
    "Dining Seating" + def,
    "Front Desk" + def,
    "Lobby Seating" + def,
    "Seating Area" + def,
    "Guest Room" + def
  ]
};
