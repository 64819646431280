const harlem = " - Aloft Harlem";

export default {
  name: "aloft",
  tags: [
    "Front Desk" + harlem,
    "Pool Table" + harlem,
    "Seating Area" + harlem,
    "Guest Room" + harlem,
    "Guest Room" + harlem,
    "Bar" + harlem,
    "Fitness Center" + harlem,
    "Guest Room" + harlem
  ]
};
