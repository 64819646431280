import factory from "@/lib/photography/image-factory";

import dt from "./doubletree";
import em from "./embassy";
import ha from "./hampton";
import hi from "./hilton";
import hgi from "./hgi";
import hws from "./homewood-suites";

const brand = "hilton";

export default {
  doubletree: factory(brand, dt.name, dt.tags),
  embassy: factory(brand, em.name, em.tags),
  hampton: factory(brand, ha.name, ha.tags),
  hilton: factory(brand, hi.name, hi.tags),
  hilton_garden_inn: factory(brand, hgi.name, hgi.tags),
  homewood_suites: factory(brand, hws.name, hws.tags)
};
