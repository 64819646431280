const evansville = " - DoubleTree by Hilton Evansville";
const cordova =
  " - DoubleTree Suites by Hilton Hotel Sacramento - Rancho Cordova";
const atlanta = " - DoubleTree by Hilton Hotel Atlanta Airport";

export default {
  name: "doubletree",
  tags: [
    "Lobby" + atlanta,
    "Market" + atlanta,
    "Breakfast Dining" + cordova,
    "Guest Room" + atlanta,
    "Lobby" + cordova,
    "Guest Room" + cordova,
    "Boardroom" + atlanta,
    "Guest Room" + atlanta,
    "Pool" + atlanta,
    "Guest Bathroom" + cordova,
    "Guest Room" + cordova,
    "Seating Area" + evansville,
    "Lobby" + evansville,
    "Atrium" + evansville
  ]
};
