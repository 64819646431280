import factory from "@/lib/photography/image-factory";

import hie from "./hie";
import hi from "./holiday-inn";

const brand = "ihg";

export default {
  holiday_inn_express: factory(brand, hie.name, hie.tags),
  holiday_inn: factory(brand, hi.name, hi.tags)
};
