const san = " - Hyatt Place San Jose/Downtown";
const grape = " - Hyatt Place Dallas/Grapevine";

export default {
  name: "hyatt-place",
  tags: [
    "Lobby Seating" + san,
    "Meeting Space" + san,
    "Banquet Seating" + san,
    "Guest Room" + san,
    "Lobby Seating" + san,
    "Beverages" + grape,
    "Dining" + grape,
    "Dining Seating" + grape
  ]
};
