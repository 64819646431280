<template>
  <div class="showcase__wrapper">
    <MinorBrandSelector v-if="showMinor" :brands="subNames" v-on:selected="selected($event)" />
    <div class="spacer" v-else></div>
    <Carousel v-if="selection === 'initial'"  :images="allImages"/>
    <Grid v-else :images="brand[selection]"/>
  </div>
</template>

<script>
import MinorBrandSelector from "@/components/photography/below/showcase/brand-selectors/Minor.vue";
import Carousel from "@/components/photography/below/showcase/display/Carousel.vue";
import Grid from "@/components/photography/below/showcase/display/Grid.vue";

export default {
  data() {
    return {
      selection: "initial"
    };
  },
  props: {
    brand: {
      type: Object,
      required: true
    },
    showMinor: {
      type: Boolean,
      default: true
    }
  },
  components: {
    MinorBrandSelector,
    Carousel,
    Grid
  },
  computed: {
    subNames: function() {
      return Object.keys(this.brand);
    },
    allImages: function() {
      return Object.values(this.brand).reduce(
        (ar, value) => ar.concat(value),
        []
      );
    }
  },
  methods: {
    selected(brand) {
      this.selection = brand;
    }
  }
};
</script>

<style scoped>
.spacer {
  height: 2rem;
  margin: 2rem auto 3rem auto;
}

.showcase__wrapper {
  margin-bottom: 3rem;
}

@media screen and (max-width: 1024px) {
  .spacer {
    margin: 0 auto;
  }
}
</style>
