const dal = " - Hyatt Regency Dallas";

export default {
  name: "hyatt-regency",
  tags: [
    "Guest Room" + dal,
    "Pool" + dal,
    "Guest Bathroom" + dal,
    "Guest Room" + dal
  ]
};
