const def = " - Residence Inn by Marriott";

export default {
  name: "residence-inn",
  tags: [
    "Lobby Seating" + def,
    "Guest Room" + def,
    "Guest Room" + def,
    "Lobby Seating" + def,
    "Patio Seating" + def,
    "Sport Court" + def,
    "Guest Room" + def,
    "Guest Room" + def,
    "Guest Room" + def,
    "Guest Room" + def,
    "Sport Court" + def,
    "Lobby Seating" + def,
    "Lobby Seating" + def,
    "Lobby Seating" + def,
    "Fitness Center" + def,
    "Outdoor Pool" + def,
    "Guest Room View" + def
  ]
};
