<template>
  <div id="interactive" class="component__wrapper">
    <div class="photography__showcase">    
      <MajorBrandSelector :brandNames="majorBrandNames" />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
    <div class="additional-content">
      <div class="normal content">
        <p>All Things Hospitality is a San Diego-based marketing agency and architectural photography vendor for the Nation’s largest hospitality brands and management companies. Our in-house professional architectural photographers are experts in the hospitality industry. We comply with brand standards and consistently deliver the highest-quality results to hundreds of properties each year.</p>
        <p>Hotel and resort photography by All Things Hospitality will accurately represent your property’s features and amenities. We guarantee to meet brand guidelines. Our experienced brand photography team assists each property in preparing for the photo shoot with thorough pre-shoot check-list, a pre-shoot conference call, and on-site staging assistance. We deliver exceptional customer service before and after the shoot to ensure your project is a complete success.</p>
      </div>
      <div class="quote-wrapper">
        <div class="quote">
          <span class="quote-mark highlight">“</span>
          <p class="quote-content normal">What makes us unique? - We understand the hotel industry. We know what the hotel is trying to sell and who they’re trying to sell to. As a former hotelier, I was involved in both operations and sales for properties ranging from select service to full service. I understand what the staff is trying to accomplish. We work with the property to overcome any challenges, meet the brand guidelines, showcase the property’s features & benefits, and communicate the property’s personality.</p>
          <span class="quote-mark highlight">”</span>
        </div>
        <p class="attribution normal highlight">– Kelly Tackert, CEO All Things Hospitality</p>
      </div>      
    </div>
  </div>
</template>

<script>
import MajorBrandSelector from "@/components/photography/below/showcase/brand-selectors/Major.vue";

export default {
  components: { MajorBrandSelector },
  data() {
    return {
      majorBrandNames: ["marriot", "hyatt", "ihg", "hilton"]
    };
  }
};
</script>

<style scoped>
.component__wrapper {
  background-color: rgb(49, 70, 87);
  padding: 3rem 0 1rem 0;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}

.additional-content {
  width: 90%;
  margin: 0 auto;
  /* padding: 0 2rem; */
}

.content {
  color: white;
  font-size: calc(1.25rem + 0.25vw);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.content p {
  width: calc(50% - 4rem);
}

/* .content p:first-of-type {
  text-align: left;
}

.content p:last-of-type {
  text-align: right;
} */

.quote-wrapper {
  width: 50%;
  margin: 0 auto;
}

.quote {
  display: flex;
  /* justify-content: space-around; */
  justify-content: space-between;
}

.quote-mark {
  font-size: calc(2rem + 0.25vw);
  /* width: 10%; */
}

.quote .quote-mark:first-child {
  align-self: baseline;
}

.quote-content {
  width: 80%;
  text-align: center;
  color: white;
  font-size: calc(1rem + 0.25vw);
  /* align-self: center; */
  /* justify-self: center; */
}

.quote .quote-mark:last-child {
  align-self: flex-end;
}

.attribution {
  text-align: right;
  margin-top: 0;
}

@media screen and (max-width: 1024px) {
  .content p {
    width: 100%;
  }
}

@media screen and (max-width: 812px) {
  .quote-wrapper {
    width: 80%;
  }
}

@media screen and (max-width: 500px) {
  .content {
    font-size: calc(1rem + 0.25vw);
  }
  .quote-wrapper {
    width: 90%;
  }
}
</style>
