<template>
<div class="major__brand__selection__wrapper">
  <div class="major__brand__controls">
    <router-link v-for="link in links" 
      :key="link.key"
      :to="link.url"
      class="logo__wrapper" 
      :aria-label="link.aria"
    >
      <component v-bind:is="link.logo" class="brand__logo"/>
    </router-link>
  </div>
</div>  
</template>

<script>
import Marriott from "@/assets/photography/brand-logos/Marriott_Logo.svg";
import Hilton from "@/assets/photography/brand-logos/Hilton_Worldwide_logo.svg";
import IHG from "@/assets/photography/brand-logos/InterContinental_Hotels_Group_logo_2017.svg";
import Hyatt from "@/assets/photography/brand-logos/Hyatt_Logo.svg";
import PATH from "@/paths";

export default {
  name: "MajorBrandSelector",
  data() {
    return {
      links: [
        {
          key: 1,
          logo: Marriott,
          url: `${PATH.PHOTOGRAPHY.DEFAULT}/${PATH.PHOTOGRAPHY.MARRIOTT}`,
          aria: "Marriott Showcase"
        },
        {
          key: 2,
          logo: Hyatt,
          url: `${PATH.PHOTOGRAPHY.DEFAULT}/${PATH.PHOTOGRAPHY.HYATT}`,
          aria: "Marriott Showcase"
        },
        {
          key: 3,
          logo: IHG,
          url: `${PATH.PHOTOGRAPHY.DEFAULT}/${PATH.PHOTOGRAPHY.IHG}`,
          aria: "Marriott Showcase"
        },
        {
          key: 4,
          logo: Hilton,
          url: `${PATH.PHOTOGRAPHY.DEFAULT}/${PATH.PHOTOGRAPHY.HILTON}`,
          aria: "Marriott Showcase"
        }
      ]
    };
  },
  props: {
    brandNames: Array,
    active: String
  },
  components: {
    Marriott,
    Hilton,
    IHG,
    Hyatt
  },
  methods: {
    update(brand) {
      this.$emit("major-brand-selected", brand);
    },
    updateRoute(url) {
      console.log("Update Route:", url);
      this.$router.push(`photography/${url}`);
    }
  }
};
</script>

<style scoped>
.major__brand__selection__wrapper {
  width: 70%;
  margin: 0 auto;

  border-top: 8px solid white;
  border-bottom: 8px solid white;

  padding: 3rem 0;
}
.major__brand__controls {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-gap: 1rem;
  justify-content: center;
  align-content: center;
}

.logo__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand__logo {
  align-self: center;
  justify-self: center;
  width: 80%;
  height: auto;
  margin: 1rem;
  fill: white;
}

.brand__logo:hover {
  cursor: pointer;
}

.logo__wrapper:nth-of-type(1).router-link-exact-active > .brand__logo {
  fill: #c6003d;
}

.logo__wrapper:nth-of-type(2).router-link-exact-active > .brand__logo {
  fill: #4b77a9;
}

.logo__wrapper:nth-of-type(3).router-link-exact-active > .brand__logo {
  fill: #d96932;
}

.logo__wrapper:nth-of-type(4).router-link-exact-active > .brand__logo {
  fill: #000000;
}

@media screen and (max-width: 1024px) {
  .major__brand__selection__wrapper {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .major__brand__selection__wrapper {
    margin: 3rem auto 1rem auto;
  }

  .major__brand__controls {
    grid-template-columns: repeat(2, 50%);
  }
}
</style>
