const def = " - Fairfield Inn & Suites";
const cuero = " - Fairfield Inn & Suites Cuero";

export default {
  name: "fairfield",
  tags: [
    "Lobby Seating" + cuero,
    "Exterior" + def,
    "Patio Seating" + def,
    "Front Desk" + cuero,
    "Lobby Seating" + def,
    "Guest Room" + def,
    "Fitness Center" + def,
    "Guest Bath" + def,
    "Guest Room" + def,
    "Breakfast" + cuero,
    "Breakfast Seating" + cuero,
    "Guest Room" + def,
    "Seating Area" + def,
    "Fitness Center" + def,
    "Meeting Space" + cuero,
    "Business Center" + def,
    "Outdoor Seating" + def,
    "Bar" + def,
    "Outdoor Pool" + def,
    "Cocktails" + def
  ]
};
