<template>
  <div id="photography-carousel">
    <agile :options="agile" >
      <div class="image-wrapper" v-for="image in selection" :key="image.id">
        <img v-lazy="image" :alt="image.alt">
      </div>
    </agile>
  </div>
</template>

<script>
import flow from "lodash/fp/flow";
import shuffle from "lodash/fp/shuffle";
import slice from "lodash/fp/slice";
import ScreenSize from "@/mixins/screen-size";

export default {
  props: {
    images: Array
  },
  mixins: [ScreenSize],
  data() {
    return {
      selection: flow(
        shuffle,
        slice(0, 8)
      )(this.images),
      agile: {
        slidesToShow: 2,
        infinite: false,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 1,
              fade: true,
              infinite: true,
              speed: 750,
              arrows: false
            }
          }
        ]
      }
    };
  },
  mounted() {
    const prev = document.querySelector(".agile__arrow--prev");
    const next = document.querySelector(".agile__arrow--next");

    prev.setAttribute("title", "Previous Image Button");
    prev.setAttribute("aria-label", "show previous image");
    next.setAttribute("title", "Next Image Button");
    next.setAttribute("aria-label", "show next image");
  },
  watch: {
    underMedLarge: function() {
      console.log("Ping");
      this.agile.slidesToShow = this.underMedLarge ? 1 : 2;
    }
  }
};
</script>

<style>
#photography-carousel .agile {
  position: relative;
}

.image-wrapper {
  padding: 1rem;
  width: 50%;
}

.image-wrapper > img {
  width: 100%;
  height: auto;
  display: block;
}

#photography-carousel .agile__list {
  width: 90%;
  margin: 0 auto;
}

#photography-carousel .agile__dots {
  position: absolute;
  bottom: -5%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

#photography-carousel .agile__dot:last-of-type {
  display: none;
}

#photography-carousel .agile__arrow {
  position: absolute;
  top: 50%;
  background: transparent;
  border: none;
}

#photography-carousel .agile__arrow.agile__arrow--prev {
  left: 1rem;
}

#photography-carousel .agile__arrow > svg {
  width: 2rem;
  height: 2rem;
}

#photography-carousel .agile__arrow:disabled {
  fill: darkgrey;
}

#photography-carousel .agile__arrow:not([disabled]) > svg:hover {
  fill: var(--ath-orange);
}

#photography-carousel .agile__arrow.agile__arrow--next {
  right: 1rem;
}

#photography-carousel .agile__dot.agile__dot--current > button {
  background: var(--ath-orange);
}

@media screen and (max-width: 1024px) {
  #photography-carousel .agile__dots {
    bottom: unset;
    left: unset;
    right: 2.5%;
    top: 30%;
    transform: translateY(-30%);
    flex-direction: column;
  }

  #photography-carousel .agile__dot:last-of-type {
    display: block;
  }

  #photography-carousel .agile__dot {
    margin: 0.25rem;
  }

  #photography-carousel .agile__dot > button {
    width: 0.75rem;
    height: 0.75rem;
  }
}
</style>
